// TODO: remove this on implementation
/* eslint-disable @typescript-eslint/no-unused-vars */

export type PlanningBlockID = number;
export type SchoolID = number;
export type SchoolPlusLevel = string;

// Probably won't need PlanningBlockShape -> Can just use geoJSON
interface PlanningBlockShape {
  [key: PlanningBlockID]: {
    geometry: unknown; // Shape
  };
}
export interface PlanningBlock {
  pb_id: PlanningBlockID;
  neighbors: PlanningBlockID[];
  hs_id: SchoolID;
  ms_id: SchoolID;
  es_id: SchoolID;
  school_codes?: SchoolPlusLevel[];
  gis_id?: string;
}

export interface PlanningBlocks {
  [key: PlanningBlockID]: PlanningBlock;
}

export const SchoolTypeToNameMap = {
  ES: "Elementary School",
  MS: "Middle School",
  HS: "High School",
};

export enum SchoolTypeOptions {
  ES = "ES",
  MS = "MS",
  HS = "HS",
}

export enum SchoolTypeFull {
  ES = "Elementary",
  MS = "Middle",
  HS = "High",
}

export type SchoolType = keyof typeof SchoolTypeOptions;

export interface School {
  id: SchoolID;
  // block?: PlanningBlockID;
  school_name: string;
  type: SchoolType;
  msFeederID?: SchoolID;
  hsFeederID?: SchoolID;
}

export interface Schools {
  [key: string]: School;
}

// Can possible be combined with `School` (above)
export type BlocksBySchool = {
  [key in SchoolPlusLevel]: { pb_ids: PlanningBlockID[]; id: SchoolPlusLevel };
};

// This allows for undo/redo. The hs/ms/es _id field shows the *previous* value before the change. Can undo actions by setting these ids to the values here.
// TODO: handle full resets.
// TODO: This won't work -- It needs to be able to handle multiple blocks changed simultaneously.
type ChangeLog = {
  id: PlanningBlockID;
  hs_id?: SchoolID;
  ms_id?: SchoolID;
  es_id?: SchoolID;
}[];

export type BlockSwaps = {
  [key in PlanningBlockID]: ValidSwap | InvalidSwap;
};

type InvalidSwap = { valid: false; cause: string }; // Cause may be a numeric code too?

type ValidSwap = { valid: true; group?: PlanningBlockID[] };

export const isValidSwap = (
  swap: ValidSwap | InvalidSwap,
): swap is ValidSwap => {
  return (swap as ValidSwap).valid == true;
};

export const isInvalidSwap = (
  swap: ValidSwap | InvalidSwap,
): swap is InvalidSwap => {
  return (swap as InvalidSwap)?.cause !== undefined;
};
