import axios from "axios";
import { UpdatedBlocksAPIResponse } from "../../components/mapping/map/edit/EditResponse";
import { ZoneWithSwaps } from "../../components/mapping/map/mapTypes";
import {
  PlanningBlock,
  PlanningBlockID,
  PlanningBlocks,
  SchoolPlusLevel,
  Schools,
} from "../../editing_planning/data_requirements";
import { getSwaps } from "../../editing_planning/func_requirements";
import { editDB } from "../../store/editStore";
import {
  UpdatedMetricsRequest,
  UpdatedMetricsResponse,
} from "../../types/data/api";
import { getIdFromSchoolPlusLevel } from "./helpers";
import { EVENTS } from "../../events/eventConstants";
import { emitter } from "../../events/events";

/**
 * This returns true if the swap is successful
 * @param {*} blockIDs array of planning block IDs which are being swapped.
 * @param {*} currentZone Selected school zone info (swaps and school IDs)
 * @returns {boolean} stat - boolean success or failure
 */
export const swapBlock = async (
  blockIDs: PlanningBlockID[],
  currentZone: ZoneWithSwaps,
  previousBlock: PlanningBlock,
  schools: Schools,
): Promise<{
  newZone: ZoneWithSwaps;
  updatedMetrics: UpdatedBlocksAPIResponse;
}> => {
  // Update local `deviations`/MetricsRequest object
  const results = await writeChangesToUpdateBlocksTable(blockIDs, currentZone);
  // Update dynamic copy of blocks.json
  const allBlocksAfterUpdate = await writeChangesToBlocksTable(
    blockIDs,
    currentZone,
  );

  const newSwaps = getSwaps(
    {
      esID: getIdFromSchoolPlusLevel(currentZone.esID),
      msID: getIdFromSchoolPlusLevel(currentZone.msID),
      hsID: getIdFromSchoolPlusLevel(currentZone.hsID),
    },
    allBlocksAfterUpdate,
    schools,
  );
  const newCurrentZone = { ...currentZone, swaps: newSwaps };

  const schoolsToUpdate = [
    `${previousBlock.es_id}:ES`,
    `${previousBlock.ms_id}:MS`,
    `${previousBlock.hs_id}:HS`,
    currentZone.esID,
    currentZone.msID,
    currentZone.hsID,
  ];

  // Update dynamic copy of BlocksBySchool.json
  await writeChangesToBlocksBySchoolTable(blockIDs, currentZone, previousBlock);
  emitter.emit(EVENTS.UPDATE_TABLES, {
    schoolsToUpdate: schoolsToUpdate,
    newBlocks: allBlocksAfterUpdate,
  });
  return { newZone: newCurrentZone, updatedMetrics: results };
};

/**
 * This returns a JSON of the updated school/system metrics given the current deviations from base.
 * @param {*} tableName dexie table which is being updated
 * @param {*} update json object to be added/modified
 * @returns {boolean} stat - boolean success or failure
 */
export const fetchMetricUpdates = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  request: UpdatedMetricsRequest,
): UpdatedMetricsResponse => {
  // Send map of current deviations from base.
  // backend returns a JSON of `school_id` : `metrics` for each of the altered schools. (And systemwide)
  // @ts-expect-error - WIP
  return { system: {}, schools: [{}] };
};

/**
 * Update the local store of blocks which have been updated. This is needed to update metrics.
 * @param {*} blockIDs ID(s) of blocks which have been swapped. Usually only one, multiple when it is an island swap (group)
 * @param {*} currentZone The currently selected block/schools.
 */
const writeChangesToUpdateBlocksTable = async (
  blockIDs: PlanningBlockID[],
  currentZone: ZoneWithSwaps,
): Promise<UpdatedBlocksAPIResponse> => {
  const newSchools = blockIDs.map((blockID) => ({
    pbID: blockID,
    esID: currentZone.esID,
    msID: currentZone.msID,
    hsID: currentZone.hsID,
  }));
  const allUpdatedBlocks = await editDB.transaction(
    "rw",
    editDB.updatedBlocks,
    async () => {
      // Use Promise.all to perform updates concurrently
      await editDB.updatedBlocks.bulkPut(newSchools);
      const updatedBlocksComplete = await editDB.updatedBlocks.toArray();
      return updatedBlocksComplete;
    },
  );

  const results = await axios.post(
    "https://dme-staging-1f15eaad7fd6.herokuapp.com/api/swap_blocks",
    {
      updates: allUpdatedBlocks,
    },
  );
  if (results?.data?.data) return results.data.data;
};

/**
 * Update the local store of blocks.json. This table is watched by the main map component and causes updates to the map.
 * @param {*} blockIDs ID(s) of blocks which have been swapped. Usually only one, multiple when it is an island swap (group)
 * @param {*} currentZone The currently selected school zone.
 */
const writeChangesToBlocksTable = async (
  blockIDs: PlanningBlockID[],
  currentZone: ZoneWithSwaps,
): Promise<PlanningBlocks> => {
  try {
    // Create a Dexie transaction
    const result = editDB.transaction("rw", editDB.blockData, async () => {
      // Use Promise.all to perform updates concurrently
      await Promise.all(
        blockIDs.map(async (blockID) => {
          await editDB.blockData.update(blockID, {
            es_id: getIdFromSchoolPlusLevel(currentZone.esID),
            ms_id: getIdFromSchoolPlusLevel(currentZone.msID),
            hs_id: getIdFromSchoolPlusLevel(currentZone.hsID),
          });
        }),
      );

      // Retrieve the full table after updates
      return editDB.blockData.toArray().then((blockData) =>
        blockData.reduce((map, obj) => {
          map[obj.pb_id] = obj;
          return map;
        }, {}),
      );
    });

    // The 'result' variable now contains the updated table
    return result;
  } catch (error) {
    // Handle errors
  }
};

/**
 * Update the local store of blocks_by_school.json. This table is used in the swapping logic.
 * @param {*} blockIDs ID(s) of blocks which have been swapped. Usually only one, multiple when it is an island swap (group)
 * @param {*} currentZone The currently selected block/schools.
 * @param {*} previousBlock The schools which this block belonged to previously. Needed to remove this block from their assigned blocks..
 */
const writeChangesToBlocksBySchoolTable = async (
  blockIDs: PlanningBlockID[],
  currentZone: ZoneWithSwaps,
  previousBlock: PlanningBlock,
) => {
  // Determine which levels are new. ES should always be new, MS/HS are only new if the new ES belongs to a different MS/HS.
  const changingLevels = ["es", "ms", "hs"].filter(
    (level) =>
      currentZone[`${level}ID`] !==
      `${previousBlock[`${level}_id`]}:${level.toUpperCase()}`,
  );

  // Get the entries for the schools we want to change (previous and new). New school for given level is first, then previous.
  const schoolsToRetrive = changingLevels.reduce(
    (schoolToRetrieveArray, level) => {
      return [
        ...schoolToRetrieveArray,
        currentZone[`${level}ID`],
        `${previousBlock[`${level}_id`]}:${level.toUpperCase()}`,
      ];
    },
    [],
  );
  const blocksBySchool =
    await editDB.blocksBySchoolData.bulkGet(schoolsToRetrive);

  const updatesNewSchools: {
    [key: SchoolPlusLevel]: { id: SchoolPlusLevel; pb_ids: PlanningBlockID[] };
  } = {};
  const updatesPrevSchools: {
    [key: SchoolPlusLevel]: { id: SchoolPlusLevel; pb_ids: PlanningBlockID[] };
  } = {};

  // For each block that is being updated, remove the block from it's past school and add it to the new school (at each school level).
  blockIDs.forEach((blockID) => {
    blocksBySchool.forEach((school, index) => {
      if (index % 2 == 0) addBlockToSchool(updatesNewSchools, school, blockID);
      else removeBlockFromSchool(updatesPrevSchools, school, blockID);
    });
  });
  // Update Dexie table.
  editDB.blocksBySchoolData.bulkPut([
    ...Object.values(updatesNewSchools),
    ...Object.values(updatesPrevSchools),
  ]);
};

/**
 * Update the object tracking schools with newly added blocks.
 * @param {*} updatesNewSchools Object containing changes to the blocksBySchool dexie table.
 * @param {*} school The school being added to.
 * @param {*} blockID The block being added.
 */
const addBlockToSchool = (
  updatesNewSchools: {
    [key: SchoolPlusLevel]: { pb_ids: PlanningBlockID[]; id: SchoolPlusLevel };
  },
  school: { id: SchoolPlusLevel; pb_ids: PlanningBlockID[] },
  blockID: PlanningBlockID,
) => {
  if (updatesNewSchools[school.id]) {
    updatesNewSchools[school.id].pb_ids = [
      ...updatesNewSchools[school.id].pb_ids,
      blockID,
    ];
  } else {
    updatesNewSchools[school.id] = {
      id: school.id,
      pb_ids: [...school.pb_ids, blockID],
    };
  }
};

/**
 * Update the object tracking schools by removing blocks.
 * @param {*} updatesPrevSchools Object containing changes to the blocksBySchool dexie table.
 * @param {*} school The school being taken from.
 * @param {*} blockID The block being removed.
 */
const removeBlockFromSchool = (
  updatesPrevSchools: {
    [key: SchoolPlusLevel]: { pb_ids: PlanningBlockID[]; id: SchoolPlusLevel };
  },
  school: { id: SchoolPlusLevel; pb_ids: PlanningBlockID[] },
  blockID: PlanningBlockID,
) => {
  if (updatesPrevSchools[school.id]) {
    updatesPrevSchools[school.id].pb_ids = updatesPrevSchools[
      school.id
    ].pb_ids.filter((id) => id !== blockID);
  } else {
    updatesPrevSchools[school.id] = {
      id: school.id,
      pb_ids: school.pb_ids.filter((id) => id !== blockID),
    };
  }
};
