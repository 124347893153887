import React from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import { useSchoolSearchData } from "../../api/seedData";
import { handleSchoolSelection } from "./map/eventHandlers";
import { useLiveQuery } from "dexie-react-hooks";
import { mapDB } from "../../store/mapStore";
import { useRouter } from "next/router";
import { useMapContextStore } from "../../context/MapContext";

interface SchoolSearchBarProps {
  routeToMap?: boolean;
}

export const SchoolSearchBar: React.FC<SchoolSearchBarProps> = ({
  routeToMap,
}) => {
  const router = useRouter();
  const { setLevel } = useMapContextStore((store) => store);
  const schoolSearchOptions = useSchoolSearchData();
  const selectedSchool = useLiveQuery(() =>
    mapDB.selectedSchool.where("active").equals(1).toArray(),
  )?.[0];
  const formattedSelection = selectedSchool
    ? {
        sch_id: selectedSchool.school_data.sch_id,
        sch_name: selectedSchool.school_data.sch_name,
        sch_level: selectedSchool.school_data.sch_level,
        fac_id: selectedSchool.school_data.fac_id,
      }
    : null;
  if (!schoolSearchOptions) return null;
  return (
    <div
      style={{
        minWidth: "240px",
      }}
    >
      <Autocomplete
        fullWidth
        color="secondary"
        value={routeToMap ? undefined : formattedSelection}
        isOptionEqualToValue={(option, value) => {
          return value
            ? Object.keys(value).every((key) => option[key] === value[key])
            : false;
        }}
        sx={{ flexDirection: "row", display: "flex" }}
        getOptionLabel={(option) => `${option.sch_name} (${option.sch_level})`}
        onChange={(_, value) => {
          if (value) {
            handleSchoolSelection({
              sch_id: value.sch_id,
              fac_id: value.fac_id,
              sch_level: value.sch_level,
            });
            setLevel(value.sch_level);
            if (routeToMap)
              router.push({ pathname: "/map", query: { mode: "view" } });
          } else
            mapDB.selectedSchool
              .where("active")
              .equals(1)
              .modify({ active: 0 });
        }}
        options={schoolSearchOptions}
        noOptionsText="No schools"
        renderInput={(params) => (
          <Box
            ref={params.InputProps.ref}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              color="secondary"
              aria-label="Search your school"
              fullWidth
              placeholder={
                routeToMap ? "or search your school" : "Search your school"
              }
            />
          </Box>
        )}
      />
    </div>
  );
};
