import { create } from "zustand";
import { SchoolType } from "../editing_planning/data_requirements";
import {
  MetricCategory,
  SearchedAddressResponse,
} from "../types/data/dataTypes";

interface MapContextStore {
  level: SchoolType;
  setLevel: (level: SchoolType) => void;
  assignments: SearchedAddressResponse | undefined;
  setAssignments: (assignments: SearchedAddressResponse | undefined) => void;
  metricCategory: MetricCategory | "";
  setMetricCategory: (metricCategory: MetricCategory | undefined) => void;
  scenario: number;
  setScenario: (scenario: number) => void;
}

export const useMapContextStore = create<MapContextStore>((set) => ({
  level: "ES",
  setLevel: (level) => set(() => ({ level: level })),
  assignments: undefined,
  setAssignments: (assignments) => set(() => ({ assignments: assignments })),
  metricCategory: "",
  setMetricCategory: (metricCategory) =>
    set(() => ({ metricCategory: metricCategory })),
  scenario: 0,
  setScenario: (scenario) => set(() => ({ scenario: scenario })),
}));

export type MapMode = "assignments" | "view" | "edit";

export const ScenarioMap = {
  0: "existingStrategyTitle",
  4: "recommendationsTitle",
};
export const ScenarioMapNumber = {
  0: "Existing",
  4: "Recommended",
  edit: "Your edits",
};
