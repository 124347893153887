export const getCoords = (e) => {
  const coordinates = e.features[0].geometry.coordinates.slice();

  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }

  return coordinates;
};

export const getRandomID = () => {
  return Math.random().toString(36).substring(2, 15);
};
