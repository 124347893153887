export const TEMP_CONTENT = {
  title: "DME Interactive Boundary Explorer",
  subtitle: "Boundary and Student Assignment Study 2023",
  option1: "Explore your assignments",
  option2: "Explore systemwide impacts",
  option2button: "Check out the full map",
  option3: "Create your own boundaries",
  option3button: "Try our boundary editing tool",
  aboutText:
    "The Office of the Deputy Mayor for Education (DME) is conducting a study to review DC Public School (DCPS) assignment policies through data analytics and community input to produce recommendations for new policies.",
  readMore: "Read more",
  tab_edit: ``,
  tab_edit_next: `You can use this tool to explore how boundary changes impact a school’s characteristics by adding planning blocks to an elementary school zone and seeing how those additions impact that school. A planning block is a spatial unit that is roughly the size of a census tract, and can be grouped together to form ES, MS, and HS school zones. 
  Begin by selecting a school on the map. As you hover over the map, you will see elementary school zones appear in gold, middle school zones highlight in orange, and high school zones highlight in blue. Planning blocks that can be added to the school appear in green, and blocks that are ineligible to be added appear in grey. Planning blocks are ineligible if they contain another elementary school, contain a middle school or high school not fed by the chosen school, or are not connected to the current school zone. Clicking on a school begins an edit session for that school: clicking on green planning blocks will expand the chosen school’s zone, and reduce the boundary of the school that previously contained that planning block. Performing a swap will cause the characteristics of both schools to update in the table below. 
  Note: This tool shows the impacts of boundary changes on in-boundary students only, whereas the scenarios developed through the broader process model impacts on out of boundary students as well. For that reason, results may vary between scenarios and boundary edits made here. Changes made to elementary school boundaries will reflect at middle and high school levels, but all changes must be made in reference to elementary school zones. 
  `,
  tab_view:
    "Click on a school or use the search bar below to learn more about a school.",
  tab_assignments:
    "Search an address in the map’s search bar to see existing school assignments, as well as potential assignments for each scenario.",
  assignments_search:
    "Search your address to learn how these proposals would impact you.",
  no_changes: "No changes",
  select_metric: "Select a metric above to learn more",
  select_school: "Select a school to learn more",
  which_school: "Which school would you like to select?",
  new_boundary: "Boundary Change",
  scenario_boundary: "Potential Boundary",
  scenario_11_description:
    "The goal of Scenario 1 is to strengthen the system of by-right neighborhood schools and balance enrollment and utilization among by-right neighborhood schools and feeders.",
  scenario_2_description:
    "The goal of Scenario 2 is to enhance programmatic options; ensure equitable programmatic options and predictable programmatic pathways",
  scenario_3_description:
    "The goal of Scenario 3 is to expand opportunities to attend quality schools and programs for those historically discriminated against and marginalized.",
  learn_more: "Click to learn more",
  location: "Location",
  comment: "Click on the map to leave a comment!",
  comment_success: "Comment added successfully",
  comment_failure: "Failed to comment. Please try again.",
  feeder_chg: "TEMPORARY",
  shared_space: "TEMPORARY",
  oob_reduce: "TEMPORARY",
  ec_annex: "TEMPORARY",
  grade_config: "TEMPORARY",
  paired_school: "TEMPORARY",
  set_aside: "TEMPORARY",
  modifications: "Modifications",
};

export const TEMP_MODAL_CONTENT = {
  title: "DME Interactive Boundary Explorer",
  intro:
    "This tool is made for the DC public school community to explore possible outcomes from the Boundary and Student Assignment Study.",
  use: "Use this web tool to:",
  one: "Learn about potential impacts to your school.",
  two: "Explore the three boundary scenarios and compare them to current conditions.",
  three:
    "Change boundaries: try your hand at changing boundaries yourself and see what the impacts are.",
  four: "Share your feedback: offer your input through the survey and comment boxes.",
  read_more: "Read more about the DME Boundary and Student Assignment Study ",
  scenario: "What is a scenario?",
  scenario_pop_up:
    "A scenario is set of potential boundaries, feeders, capital programs, and related policies that were modeled to understand approximate impacts on DC public schools. Scenarios are tools for decision-making – they are not final maps or options. The final boundaries may draw upon elements of all three scenarios, or include additional changes or policies not yet shown.",
  scenario_read_more:
    "Read more about scenarios here (LINK to something on project home page?).",
};

export const NEW_TEMP_CONTENT = {
  clear: "Clear",
};
