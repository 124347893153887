import React, { useState, useMemo, useEffect } from "react";
import { Box, InputAdornment, TextField, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash/debounce";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { useLiveQuery } from "dexie-react-hooks";
import {
  AddressSearch,
  globalDB,
  useSavedAddress,
} from "../../store/globalStore";
import { useRouter } from "next/router";
import { SearchedAddressResponse } from "../../types/data/dataTypes";
import { useMapContextStore } from "../../context/MapContext";

interface AddressSearchBarProps {
  routeToMap: boolean;
}

// TODO: Set options array to prev. searches when empty.
export const AddressSearchBar: React.FC<AddressSearchBarProps> = ({
  routeToMap,
}) => {
  const user = useLiveQuery(() => globalDB.userData.toArray());
  const theme = useTheme();
  const [value, setValue] = useState<AddressSearch>(null);
  const [options, setOptions] = useState<AddressSearch[]>([]);
  const setAssignments = useMapContextStore((store) => store.setAssignments);
  const savedAddress = useSavedAddress();
  const router = useRouter();

  useEffect(() => {
    if (savedAddress && !routeToMap) {
      handleValueChange(savedAddress);
    }
  }, [savedAddress]);

  const changeHandler = (_, value, reason) => {
    if (reason === "input" && value !== "") handleQueryChange(value);
  };

  const handleQueryChange = (query) => {
    axios.get(`mar_address/${String(query).toUpperCase()}`).then((res) => {
      if (
        res.data.length === 0 ||
        (res.data.data && res.data.data[0].mar_id === 0)
      ) {
        setOptions([]);
      } else if (value !== null) {
        setOptions([value]);
      } else {
        setOptions(res.data);
      }
    });
  };

  const handleValueChange = async (
    newValue: AddressSearch,
    firstSearch: boolean = false,
  ) => {
    setValue(newValue);
    if (firstSearch)
      globalDB.addressSearch.toCollection().modify((record) => {
        record.active = 0;
      });
    if (!newValue) {
      setOptions([]);
      setAssignments(undefined);
      globalDB.addressSearch.where("active").equals(1).modify({ active: 0 });
      return;
    }
    if (firstSearch)
      globalDB.addressSearch.put({
        address: newValue.address,
        mar_id: newValue.mar_id,
        active: 1,
      });

    setOptions([newValue]);
    if (firstSearch && user)
      axios.post("user_data/", {
        user_id: user[0]?.user_id,
        type: "address_search",
        user_data: {
          address: newValue.address,
          mar_id: newValue.mar_id,
        },
      });

    const scenarioAssignments = await axios.get(
      `https://dme-staging-v2-a3966fffc635.herokuapp.com/api/proposed_scenario_assignment/${String(
        newValue.mar_id,
      )}`,
    );

    const searchedAddressResponse: SearchedAddressResponse =
      scenarioAssignments.data.data;
    if (!searchedAddressResponse) {
      alert("Failed to get address information");
      return;
    }
    setAssignments(searchedAddressResponse);
    if (routeToMap)
      router.push({ pathname: "/map", query: { mode: "assignments" } });
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 400),
    [],
  );

  return (
    <div
      style={{
        minWidth: "240px",
        borderRadius: routeToMap ? 4 : undefined,
      }}
    >
      <Autocomplete
        id="address-lookup"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.address
        }
        options={!options ? [{ address: "Searching...", mar_id: 0 }] : options}
        includeInputInList
        noOptionsText={value ? "No locations" : "Start typing to search..."}
        value={value}
        onChange={(_, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          handleValueChange(newValue, true);
        }}
        onInputChange={debouncedChangeHandler}
        sx={{
          flexDirection: "row",
          display: "flex",
        }}
        renderInput={(params) => (
          <Box
            ref={params.InputProps.ref}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingTop: 0,
                  borderColor: theme.palette.primary.main,
                  paddingBottom: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              color="primary"
              aria-label="Search your address"
              placeholder="Search your address"
            />
          </Box>
        )}
      />
    </div>
  );
};
