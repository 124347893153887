/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BlockSwaps,
  PlanningBlock,
  PlanningBlockID,
  PlanningBlocks,
  School,
  SchoolID,
  SchoolPlusLevel,
  SchoolType,
  SchoolTypeOptions,
  Schools,
} from "./data_requirements";

const searchNeighbors = (
  block: PlanningBlock,
  discoveredNeighbors: Set<PlanningBlockID>,
  planningBlocks: PlanningBlocks,
  level: SchoolType,
): { schools: SchoolPlusLevel[]; group: Set<PlanningBlockID> } => {
  const searchStack = [block.pb_id];
  const foundBlocksInZone = new Set<PlanningBlockID>();
  const schoolsInGroup: SchoolPlusLevel[] = [];
  while (searchStack.length) {
    const currBlock = searchStack.pop();
    discoveredNeighbors.add(currBlock);
    if (foundBlocksInZone.has(currBlock)) continue;
    const searchNeighbor = planningBlocks[currBlock];
    // This block is not part of the zone we are looking at. Can be ignored.
    if (
      searchNeighbor[`${level.toLowerCase()}_id`] !==
      block[`${level.toLowerCase()}_id`]
    )
      continue;
    foundBlocksInZone.add(currBlock);
    if (searchNeighbor.school_codes)
      schoolsInGroup.push(...searchNeighbor.school_codes);

    // Add neighbors to search stack if they are not the block in question.
    searchNeighbor.neighbors.forEach((neighbor) => {
      if (!discoveredNeighbors.has(neighbor)) searchStack.push(neighbor);
    });
  }
  return { schools: schoolsInGroup, group: foundBlocksInZone };
};

/**
 * Get the groups which would result from removing this block. If it is one group, then moving this block will not create an island.
 */
const getRemainingGroups = (
  block: PlanningBlock,
  planningBlocks: PlanningBlocks,
  level: SchoolType,
): { schools: SchoolPlusLevel[]; group: Set<PlanningBlockID> }[] => {
  const remainingGroups: {
    schools: SchoolPlusLevel[];
    group: Set<PlanningBlockID>;
  }[] = [];
  const discoveredNeighbors = new Set<PlanningBlockID>([block.pb_id]);
  block.neighbors.forEach((neighbor) => {
    if (discoveredNeighbors.has(neighbor)) return;
    discoveredNeighbors.add(neighbor);
    const neighborBlock = planningBlocks[neighbor];
    if (
      neighborBlock[`${level.toLowerCase()}_id`] !==
      block[`${level.toLowerCase()}_id`]
    ) {
      return;
    }
    remainingGroups.push(
      searchNeighbors(
        neighborBlock,
        discoveredNeighbors,
        planningBlocks,
        level,
      ),
    );
  });
  return remainingGroups;
};

/**
 * Determine if the `currentBlock` can be added to the currently selected elementary school zone.
 * @returns Either a validSwap option, an invalidSwap option, or false if this swap would not create an island.
 */
const getIslandSwaps = (
  neighborID: PlanningBlockID,
  allSwaps: BlockSwaps,
  schools: { esID: SchoolID; msID: SchoolID; hsID: SchoolID },
  planningBlocks: PlanningBlocks,
  schoolsData: Schools,
): boolean => {
  // TODO: Handle feeder assignment islands!
  const blockToBeRemoved = planningBlocks[neighborID];
  const neighbors = blockToBeRemoved.neighbors;
  for (const schoolLevel of ["MS", "HS"] as SchoolType[]) {
    const remainingGroups = getRemainingGroups(
      blockToBeRemoved,
      planningBlocks,
      schoolLevel,
    );
    if (remainingGroups.length > 1) {
      allSwaps[neighborID] = {
        valid: false,
        cause: `Adding this block would create a discontiguous ${schoolLevel} zone.`,
      };
      return true;
    }
  }

  const neighborsInZone = neighbors.filter(
    (neighbor) => planningBlocks[neighbor].es_id === blockToBeRemoved.es_id,
  );
  if (neighborsInZone.length <= 1) return false;
  const remainingGroups = getRemainingGroups(
    blockToBeRemoved,
    planningBlocks,
    "ES",
  );
  if (remainingGroups.length == 1) return false;
  for (let index = 0; index < remainingGroups.length; index++) {
    let hasES = false;
    let hasExternalSchool = false;
    for (const school of remainingGroups[index].schools) {
      const schoolObject = schoolsData[school];
      if (schoolObject.type == "ES") {
        // If the group is an ES, we can remove it. If this block can be swapped, we will take the other groups.
        remainingGroups.splice(index, 1);
        hasES = true;
        break;
      }
      if (schoolObject.id == schools.msID || schoolObject.id == schools.hsID)
        break;
      hasExternalSchool = true;
    }
    if (hasExternalSchool && !hasES) {
      allSwaps[neighborID] = {
        valid: false,
        cause: "Adding this block would create an isolated school zone.", // TODO: better description
      };
      return true;
    }
  }

  allSwaps[neighborID] = {
    valid: true,
    group: remainingGroups.reduce(
      (allBlocks, currentGroup) => [
        ...allBlocks,
        ...Array.from(currentGroup.group),
      ],
      [],
    ),
  };
  return true;
};

/**
 * Check to see if this block is a valid swap given the schools which it contains.
 *
 * @param schools Schools located in the current block.
 * @param currentSchool School zone being added to.
 * @returns
 */
const checkSchoolBlocker = (
  schools: SchoolPlusLevel[] | undefined,
  currentSchool: School,
  allSwaps: BlockSwaps,
  neighborID: PlanningBlockID,
  schoolsData: Schools,
): boolean => {
  if (!schools) return false;
  for (const schoolID of schools) {
    const school = schoolsData[schoolID];
    if (school.type === SchoolTypeOptions.ES) {
      allSwaps[neighborID] = {
        valid: false,
        cause: "Contains elementary school.",
      };
      return true;
    }
    if (
      school.type === SchoolTypeOptions.MS &&
      school.id != currentSchool.msFeederID
    ) {
      allSwaps[neighborID] = {
        valid: false,
        cause:
          'Contains middle school with is not "fed" by selected elementary.',
      };
      return true;
    }
    if (
      school.type === SchoolTypeOptions.HS &&
      school.id != currentSchool.hsFeederID
    ) {
      allSwaps[neighborID] = {
        valid: false,
        cause: 'Contains high school with is not "fed" by selected elementary.',
      };
      return true;
    }
  }
  return false;
};

export const getSwaps = (
  blockSchools: {
    esID: SchoolID;
    msID: SchoolID;
    hsID: SchoolID;
  },
  planningBlocks: PlanningBlocks,
  schools: Schools,
  // schoolBoundaries: SchoolBoundaries = TEMP_SCH_BOUNDARIES,
): BlockSwaps => {
  const allNeighborIDs = getAllNeighbors(
    blockSchools.esID,
    planningBlocks,
    // schoolBoundaries,
  );
  const currentSchool = schools[`${blockSchools.esID}:ES`];

  const allSwaps: BlockSwaps = {};
  allNeighborIDs.forEach((neighborID) => {
    const neighbor = planningBlocks[neighborID];
    if (
      checkSchoolBlocker(
        neighbor.school_codes,
        currentSchool,
        allSwaps,
        neighborID,
        schools,
      )
    )
      return;
    if (
      getIslandSwaps(
        neighborID,
        allSwaps,
        blockSchools,
        planningBlocks,
        schools,
      )
    )
      return;
    allSwaps[neighborID] = { valid: true };
  });
  return allSwaps;
};

const getAllNeighbors = (
  esID: SchoolID,
  planningBlocks: PlanningBlocks,
  // schools: SchoolBoundaries = TEMP_SCH_BOUNDARIES,
): Set<PlanningBlockID> => {
  // TODO: May want to replace this with a schoolboundaries table if it is slow...
  const blocks = Object.values(planningBlocks)
    .filter((block) => block.es_id === esID)
    .map((block) => block.pb_id);

  const neighbors = new Set<PlanningBlockID>();
  blocks.forEach((pb) =>
    planningBlocks[pb].neighbors.forEach((neighbor) => {
      if (planningBlocks[neighbor].es_id !== esID) neighbors.add(neighbor);
    }),
  );
  return neighbors;
};
