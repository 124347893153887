import { useCallback, useEffect } from "react";
import { EventEmitter } from "eventemitter3";

export const emitter = new EventEmitter();

export const useSub = (event, callback, dependencies = []) => {
  const unsubscribe = () => {
    emitter.off(event, callback);
  };
  const memoizedCallback = useCallback(callback, dependencies);

  useEffect(() => {
    emitter.on(event, memoizedCallback);
    return unsubscribe;
  }, [event, memoizedCallback]);

  return unsubscribe;
};

export const usePub = () => {
  return (event, data?: object) => {
    emitter.emit(event, data);
  };
};
