import Dexie from "dexie";
import {
  PlanningBlock,
  PlanningBlockID,
  SchoolPlusLevel,
} from "../editing_planning/data_requirements";
// eslint-disable-next-line import/named
import { Feature, Polygon, MultiPolygon } from "@turf/turf";

class EditStore extends Dexie {
  selectedZone!: Dexie.Table<SelectedZone, number>;
  updatedBlocks!: Dexie.Table<UpdatedBlocks, PlanningBlockID>;
  blockData!: Dexie.Table<PlanningBlock, PlanningBlockID>;
  blocksBySchoolData!: Dexie.Table<
    { pb_ids: PlanningBlockID[]; id: SchoolPlusLevel },
    SchoolPlusLevel
  >;
  constructor(name: string) {
    super(name);

    // TODO: adjust versioning?
    this.version(6).stores({
      selectedZone: "++esSchoolID, esBoundary, msBoundary, hsBoundary",
      updatedBlocks: "pbID",
      blockData: "pb_id",
      blocksBySchoolData: "id",
    });
  }
}

export interface UpdatedBlocks {
  pbID: PlanningBlockID;
  esID: SchoolPlusLevel;
  msID: SchoolPlusLevel;
  hsID: SchoolPlusLevel;
}

interface SelectedZone {
  esSchoolID: SchoolPlusLevel;
  esBoundary: Feature<Polygon | MultiPolygon>;
  msBoundary: Feature<Polygon | MultiPolygon>;
  hsBoundary: Feature<Polygon | MultiPolygon>;
}

const editDB: EditStore = new EditStore("editDB");
export const clearEdits = async () => {
  editDB.blockData.clear();
  editDB.updatedBlocks.clear();
  editDB.blocksBySchoolData.clear();
};

export { editDB };
