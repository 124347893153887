import Dexie from "dexie";
import { ScenariosAPIResponse } from "../types/data/api";

class MapDexie extends Dexie {
  selectedSchool!: Dexie.Table<SelectedSchool, number>;
  swappedBlocks!: Dexie.Table<SwappedBlocks, number>;

  constructor(name: string) {
    super(name);

    //
    // Define tables and indexes
    // (Here's where the implicit table props are dynamically created)
    //
    this.version(2).stores({
      selectedSchool: "++, sch_id, fac_id, school_data, active",
      swappedBlocks: "from_block, to_block",
    });
  }
}

export interface SelectedSchool {
  id?: number;
  school_data: ScenariosAPIResponse;
  active: number;
}

interface SwappedBlocks {
  from_block: string;
  to_block: string;
}

const mapDB = new MapDexie("mapObjects");

export { mapDB };
