import maplibregl, {
  LngLatBoundsLike,
  LngLatLike,
  SourceSpecification,
} from "maplibre-gl";

export const BOUNDS: LngLatBoundsLike = [
  [-77.2627, 38.7209], // Southwest coordinates
  [-76.8123, 39.0613], // Northeast coordinates
];

export const CENTER: LngLatLike = [-77.0366, 38.885];

export const SCH_POPUP = new maplibregl.Popup({
  closeButton: false,
  closeOnClick: false,
  className: "school-popup",
});

export enum MAP_LAYERS {
  schoolPoints = "school-points",
  searchedAddress = "searched-address",
  planningBlocks = "planning-blocks",
  planningBlockChanges = "planning-block-changes",
  dualRights = "dual-rights",
  swappableBlocks = "planning-blocks-swappable",
  allBoundaries = "all-boundaries",
  schoolBoundaries = "school-boundaries",
}

export const SOURCE_PB_SWAPPABLE: SourceSpecification = {
  // set planning block ID as shape ID.
  promoteId: "pb_id",
  type: "geojson",
  data: "/static/planning_blocks_rewound_3_to5.geojson",
};

export const SOURCE_SCHOOL_POINTS: SourceSpecification = {
  type: "geojson",
  data: "https://dme-staging-v2-a3966fffc635.herokuapp.com/api/tiles/scenario_school_points",
};

export const SOURCE_PB: SourceSpecification = {
  type: "vector",
  tiles: [
    "https://dme-staging-v2-a3966fffc635.herokuapp.com/api/tiles/recommended_planning_blocks/{z}/{x}/{y}",
  ],
};

export const SOURCE_SCHOOL_BOUNDARIES: SourceSpecification = {
  type: "vector",
  tiles: [
    "https://dme-staging-v2-a3966fffc635.herokuapp.com/api/tiles/recommended_school_boundaries/{z}/{x}/{y}",
  ],
};

export const MAP_COLORS = {
  ES: "#FFD335",
  MS: "#FF9635",
  HS: "#3378e8",
  BLACK: "#000",
  GREEN: "#1bf22d",
  GREY: "#a0a0a0",
};
export const COLORS = {
  LIGHT: "#F0EAEA",
  DARK: "#1D0101",
  VALID: "#17b024",
  INVALID: "#b01717",
};

export const VIEW_LAYERS = [
  MAP_LAYERS.schoolPoints,
  MAP_LAYERS.planningBlocks,
  MAP_LAYERS.schoolBoundaries,
  MAP_LAYERS.planningBlockChanges,
  MAP_LAYERS.dualRights,
];

export const EDIT_LAYERS = [
  MAP_LAYERS.allBoundaries,
  MAP_LAYERS.swappableBlocks,
  MAP_LAYERS.schoolPoints,
];

export const ASSIGNMENT_LAYERS = [
  MAP_LAYERS.schoolPoints,
  MAP_LAYERS.searchedAddress,
  MAP_LAYERS.planningBlocks,
  MAP_LAYERS.schoolBoundaries,
  MAP_LAYERS.planningBlockChanges,
  MAP_LAYERS.dualRights,
];
