// eslint-disable-next-line import/named
import { FeatureCollection, Polygon } from "@turf/turf";
import {
  BlocksBySchool,
  PlanningBlocks,
  Schools,
  SchoolType,
} from "../editing_planning/data_requirements";
import { useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { editDB } from "../store/editStore";
import { MetricsByScenario, SchoolSearchData } from "../types/data/api";
import axios from "axios";
import { useMapContextStore } from "../context/MapContext";

const getBlockData = async (): Promise<PlanningBlocks> => {
  return await fetch("/static/blocks.json").then((res) => res.json());
};

const getBlocksBySchoolData = async (): Promise<PlanningBlocks> => {
  return await fetch("/static/blocks_by_school.json").then((res) => res.json());
};

// TODO: add type to this unknown.
const getBlockShapeData = async (): Promise<FeatureCollection<Polygon>> => {
  return await fetch(
    "/static/geom_reductions/planning_blocks_rewound_3_to5_sp2_simp1ft_buff1_wgs_updated52.geojson",
  ).then((res) => res.json());
};

export const useBlocks = (): PlanningBlocks | undefined => {
  const localBlockDatabase = useLiveQuery(() => editDB.blockData?.toArray());
  const [blocks, setBlocks] = useState<PlanningBlocks | undefined>(undefined);
  useEffect(() => {
    if (localBlockDatabase && localBlockDatabase.length == 0) {
      getBlockData().then((data) => {
        editDB.blockData.bulkPut(Object.values(data));
      });
    }
    if (localBlockDatabase && localBlockDatabase.length > 0) {
      setBlocks(
        localBlockDatabase.reduce((map, obj) => {
          map[obj.pb_id] = obj;
          return map;
        }, {}),
      );
    }
  }, [localBlockDatabase]);
  return blocks;
};

export const useBlockShapeData = () => {
  const [blockShapeData, setBlockShapeData] = useState<
    FeatureCollection<Polygon> | undefined
  >(undefined);
  useEffect(() => {
    getBlockShapeData().then((data) => {
      setBlockShapeData(data);
    });
  }, []);
  return blockShapeData;
};

const getSchoolData = async (): Promise<Schools> => {
  return await fetch("/static/schools.json").then((res) => res.json());
};

export const useSchoolData = () => {
  const [schoolData, setSchoolData] = useState<Schools | undefined>(undefined);
  useEffect(() => {
    getSchoolData().then((data) => setSchoolData(data));
  }, []);
  return schoolData;
};

export const useBlocksBySchoolData = (): BlocksBySchool | undefined => {
  const blocksBySchoolLocalCopy = useLiveQuery(
    () => editDB.blocksBySchoolData?.toArray(),
  );
  const [blocksBySchool, setBlocksBySchool] = useState<
    BlocksBySchool | undefined
  >(undefined);
  useEffect(() => {
    if (blocksBySchoolLocalCopy && blocksBySchoolLocalCopy.length == 0) {
      getBlocksBySchoolData().then((data) => {
        editDB.blocksBySchoolData.bulkPut(Object.values(data));
      });
    }
    if (blocksBySchoolLocalCopy && blocksBySchoolLocalCopy.length > 0) {
      setBlocksBySchool(
        blocksBySchoolLocalCopy.reduce((map, obj) => {
          map[obj.id] = obj;
          return map;
        }, {}),
      );
    }
  }, [blocksBySchoolLocalCopy]);
  return blocksBySchool;
};

// TODO: SET up api that fetches all school names and IDs. If that is slow/bad then can set it up similar to address search.
const getSchoolSearchData = async (): Promise<SchoolSearchData[]> => {
  return await axios.get("/schools").then((res) => res.data);
};

export const useSchoolSearchData = () => {
  const [schoolSearchData, setSchoolSearchData] = useState<
    SchoolSearchData[] | undefined
  >(undefined);
  const { level } = useMapContextStore((store) => store);
  useEffect(() => {
    getSchoolSearchData().then((data) =>
      setSchoolSearchData(
        data.map((school) => ({
          ...school,
          id_lvl: `${school.sch_id} (${school.sch_level})`,
        })),
      ),
    );
  }, [level]);
  return schoolSearchData;
};

const getSystemwideData = async (level: SchoolType) => {
  return await axios
    .get(
      `https://dme-staging-v2-a3966fffc635.herokuapp.com/api/level_scenario/${level}`,
    )
    .then((res) => res.data);
};
export const useSystemwideData = () => {
  const [systemData, setSystemData] = useState<
    Partial<MetricsByScenario> | undefined
  >();
  const level = useMapContextStore((store) => store.level);

  useEffect(() => {
    getSystemwideData(level).then((data) => {
      setSystemData(data);
    });
  }, [level]);
  return systemData;
};
