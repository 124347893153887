import axios from "axios";
import { mapDB } from "../../../store/mapStore";
import { getCoords } from "./helpers";
import { MAP_LAYERS, SCH_POPUP } from "./constants";
import {
  BlockSwaps,
  PlanningBlocks,
  SchoolID,
  SchoolType,
  isValidSwap,
} from "../../../editing_planning/data_requirements";
import { MutableRefObject, SetStateAction } from "react";
import { Map, MapLayerMouseEvent } from "maplibre-gl";
import { ZoneWithSwaps } from "./mapTypes";
import { swapBlock } from "../../../utils/editing/editing";
import { SchoolSelection } from "./modes/configureAssignments";
import { UpdatedBlocksAPIResponse } from "./edit/EditResponse";

// Do we want this API to take schoolPlusLevel.
export const handleSchoolSelection = async (selection: {
  sch_id: SchoolID;
  fac_id: string;
  sch_level: SchoolType;
}) => {
  const currentSelection = await mapDB.selectedSchool
    .where("active")
    .equals(1)
    .toArray();

  if (
    currentSelection[0]?.school_data &&
    currentSelection[0]?.school_data.sch_id === selection.sch_id &&
    currentSelection[0]?.school_data.fac_id === selection.fac_id &&
    currentSelection[0]?.school_data.sch_level === selection.sch_level
  ) {
    mapDB.selectedSchool.toCollection().modify({ active: 0 });
    return;
  }
  axios
    .get(
      `https://dme-staging-v2-a3966fffc635.herokuapp.com/api/school_scenario/${selection.sch_id}/${selection.fac_id}/${selection.sch_level}`,
    )

    .then((res) => {
      mapDB.transaction("rw", mapDB.selectedSchool, async () => {
        // Use Promise.all to perform updates concurrently
        mapDB.selectedSchool.toCollection().modify({ active: 0 });

        mapDB.selectedSchool.put({
          school_data: res.data,
          active: 1,
        });
      });
    });
};

export const schoolClick = (
  e,
  setShowMultiSchoolModal: React.Dispatch<SetStateAction<SchoolSelection>>,
  mapSchoolLevel: MutableRefObject<SchoolType>,
) => {
  const clickedSchools: {
    sch_level: SchoolType;
    fac_id: string;
    sch_id: SchoolID;
    sch_name: string;
  }[] = e.features.map(
    (f) =>
      [
        {
          sch_id: f.properties.sch_id,
          fac_id: f.properties.fac_id,
          sch_level: f.properties.school_level,
          sch_name: f.properties.school_name,
        },
      ][0],
  );
  if (clickedSchools.length > 1) {
    // TODO: Handle multiple schools clicked at once.
    setShowMultiSchoolModal(clickedSchools);
    return;
  }
  // perform a post request for each item in clicked schools, then write the result to indexeddb
  handleSchoolSelection({
    ...clickedSchools[0],
    sch_level: mapSchoolLevel.current,
  });
};

export const hoverSchool = (e, map) => {
  map.current.getCanvas().style.cursor = "pointer";
  const sch_names = e.features.map((f) => f.properties.school_name);
  const coordinates = getCoords(e);
  const html = sch_names.map((name) => `<p>${name}</p>`).join("\n");
  SCH_POPUP.setLngLat(coordinates).setHTML(html).addTo(map.current);
};

export const unhoverSchool = (map) => {
  map.current.getCanvas().style.cursor = "";
  SCH_POPUP.remove();
};

export const setSwapHighlighting = (
  swaps: BlockSwaps,
  map: MutableRefObject<Map>,
  remove = false,
) => {
  Object.entries(swaps).map(([pbID, swap]) => {
    map.current.setFeatureState(
      { source: MAP_LAYERS.swappableBlocks, id: pbID },
      { valid: remove ? undefined : swap.valid },
    );
    map.current.setFeatureState(
      { source: MAP_LAYERS.swappableBlocks, id: pbID },
      { swap: remove ? undefined : true },
    );
    if ("group" in swap) {
      map.current.setFeatureState(
        { source: MAP_LAYERS.swappableBlocks, id: pbID },
        { group: remove ? undefined : swap.group },
      );
    }
  });
};

export const handleSwap = (
  currentZone: ZoneWithSwaps,
  e: MapLayerMouseEvent,
  blocks: PlanningBlocks,
  schools,
): Promise<
  | undefined
  | { newZone: ZoneWithSwaps; updatedMetrics: UpdatedBlocksAPIResponse }
> => {
  const pbID = e.features[0].properties.pb_id;
  const swap = currentZone?.swaps[pbID];
  if (!swap) {
    return undefined;
  }
  if (isValidSwap(swap)) {
    return swapBlock(
      swap.group ? [...swap.group, pbID] : [pbID],
      currentZone,
      blocks[pbID],
      schools,
    );
  } else {
    return undefined;
  }
  // Update
};
