import Dexie from "dexie";
import { useEffect, useState } from "react";

class GlobalDB extends Dexie {
  language!: Dexie.Table<Language, number>;
  pageContent!: Dexie.Table<PageContent, number>;
  addressSearch!: Dexie.Table<AddressSearch, number>;
  surveyResponses!: Dexie.Table<SurveyResponse, number>;
  userData!: Dexie.Table<UserData, number>;
  showModal!: Dexie.Table<ShowModal, number>;

  constructor(name: string) {
    super(name);

    //
    // Define tables and indexes
    // (Here's where the implicit table props are dynamically created)
    //
    this.version(2).stores({
      language: "++id, iso_code",
      pageContent: "lang, active",
      addressSearch: "++id, address, mar_id, active",
      surveyResponses: "++id, responses, submitted",
      userData: "++id, user_id, ip",
      showModal: "id, showModal",
    });
  }
}

interface ShowModal {
  id: number;
  showModal: boolean;
}

interface Language {
  id?: number;
  iso_code: string;
}
interface PageContent {
  lang: string;
  active: number;
  page_content: PageContentDict;
}

export interface AddressSearch {
  mar_id: number;
  address: string;
  active?: number;
  id?: number;
}

interface SurveyResponse {
  id?: number;
  responses: unknown;
  submitted: number;
}

interface UserData {
  id?: number;
  user_id: string;
  ip?: string;
}

export interface PageContentDict {
  data: { [key in string]: string };
}

const globalDB: GlobalDB = new GlobalDB("globalObjects");

export { globalDB };

export const useSavedAddress = () => {
  const [savedAddress, setSavedAddress] = useState<AddressSearch>();
  useEffect(() => {
    globalDB.addressSearch
      .where("active")
      .equals(1)
      .toArray()
      .then((response) => setSavedAddress(response[0]));
  }, []);
  return savedAddress;
};
